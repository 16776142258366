export const IMG_PATH =
    [
    '/uploads/sigs/jugaad.jpeg',               
    '/uploads/sigs/hachathon.png',
    '/uploads/sigs/sangamitra.jpeg',
    '/uploads/sigs/mozilla.png',
    '/uploads/sigs/multiverse.jpg',                
    '/uploads/sigs/blog.jpg',
    '/uploads/sigs/apprentice.jpg',
    '/uploads/sigs/linux.jpeg',
];
 